<template>
  <v-card :outlined="outlined" :class="classNames">
    <v-card-title v-if="title">{{ title }}</v-card-title>
    <v-card-subtitle v-if="subTitle">{{ subTitle }}</v-card-subtitle>
    <v-card-text>
      <v-container v-if="loading" class="text-center">
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-container>
      <v-container v-else-if="labels.length === 0" class="text-center">
        {{ noDataText }}
      </v-container>
      <vue-apex-charts
        v-show="!loading && labels.length > 0"
        type="bar"
        :height="height"
        :options="chartOptions"
        :series="series"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

import { chartColors, noDataText } from "@/utils/constants";

export default {
  name: "BarChart",
  components: {
    VueApexCharts,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    classNames: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "355",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    series: {
      type: Array,
      default: () => [],
    },
    legend: {
      type: Boolean,
      default: true,
    },
    datalabels: {
      type: Boolean,
      default: true,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    formatter: {
      type: Function,
      default: (val) => val,
    },
    colors: {
      type: Array,
      default: () => chartColors,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    xAxis: {
      type: Object,
      default: () => ({
        categories: [],
        labels: {
          rotate: -45,
        },
        style: {
          fontSize: "12px",
          fontWeight: 400,
        },
      }),
    },
    yAxis: {
      type: Array,
      default: () => [
        {
          min: 0,
          forceNiceScale: true,
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: 400,
            },
          },
        },
      ],
    },
  },
  data() {
    return {
      noDataText,
      chartOptions: {
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top",
            },
          },
        },
        legend: {
          show: this.legend,
          position: "bottom",
          fontSize: "14px",
          fontFamily: '"Inter", sans-serif',
        },
        colors: this.colors,
        dataLabels: {
          enabled: this.datalabels,
          textAnchor: "middle",
          style: {
            colors: ["#373D3FFF"],
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
          },
          offsetY: -30,
          formatter: this.formatter,
          dropShadow: {
            enabled: true,
          },
        },
        ...(this.xAxis ? { xaxis: this.xAxis } : {}),
        ...(this.yAxis ? { yaxis: this.yAxis } : {}),
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: this.formatter,
          },
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
  methods: {
    updateOptions() {
      this.chartOptions = {
        ...this.chartOptions,
        series: this.series,
        labels: this.labels,
      };
    },
  },
  watch: {
    series() {
      this.updateOptions();
    },
    labels() {
      this.updateOptions();
    },
  },
};
</script>
