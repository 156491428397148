import uniq from "lodash/uniq";

export const ingredientGroupAutoCompleteValue = (ingredientGroup) =>
  `Liste "${ingredientGroup.name}" : ${ingredientGroup.ingredients
    .split(",")
    .join(", ")}`;

export const ingredientsFromGroupAutoCompleteValue = (name) => {
  const matches = name.match(/^Liste "([^\"]+)" : (.+)$/);

  return matches === null ? [name] : matches[2].split(", ");
};

export const ingredientListFromAutoCompleteValues = (values) => {
  const list = values.map((value) =>
    ingredientsFromGroupAutoCompleteValue(value)
  );

  return uniq(list.flat());
};
