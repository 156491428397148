// Given by regie team :
const AVG_CONSENT_RATIO = 0.75;

export const extrapolateWithConsentRatio = (value) =>
  // value;
  Math.round(value / AVG_CONSENT_RATIO);

export const extrapolateDatasetWithConsentRatio = (item) => {
  for (const key of ["ca", "views", "sessions"]) {
    if (item[key] === undefined) {
      continue;
    }

    // GA4 gives data for people that have consented.
    // Extrapolate to get data for every hypothetical visitor :
    item[key] = extrapolateWithConsentRatio(item[key]);
  }

  return item;
};
