export const chartColors = [
  //https://neoground.com/docs/neowx-material/config.charts
  // Palette 3
  "#33b2df",
  "#546e7a",
  "#d4526e",
  "#13d8aa",
  "#a5978b",
  // Palette 5
  "#2b908f",
  "#f9a3a4",
  "#90ee7e",
  "#f48024",
  "#69d2e7",
  // Palette 7
  "#d7263d",
  "#1b998b",
  "#2e294e",
  "#f46036",
  "#e2c044",
  // Palette 9
  "#5c4742",
  "#a5978b",
  "#8d5b4d",
  "#5a2a27",
  "#c4bbaf",
  // Palette 1
  "#775dd0",
  // Palette 2
  "#4caf50",
  "#ff9800",
  // Palette 4
  "#34ecdc4",
  "#fd6a6a",
  // Palette 6
  "#f86624",
  "#662e9b",
  "#c5d86d",
  // Palette 10
  "#a300d6",
  "#5653fe",
];

const chartDefault = "#33b2df";

export const colors = {
  chartDefault,
  sessions: "#008ffb", // "#f9c80e",
  views: chartDefault,
  ca: "#feb019", // "#f44336",
  rpm: "#4caf50",
  objective: "#d7263d",
  accomplished: "#5c4742",
};

export const noDataText = "Aucune donnée disponible";

export const NOTIF_DISPLAY_DURATION = 5000;
