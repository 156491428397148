<template>
  <v-list-item link @click="exportPDF" :disabled="disabled">
    <v-list-item-title>Export PDF</v-list-item-title>
  </v-list-item>
</template>

<script>
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export default {
  name: "ExportPdfButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    getContainerRef: {
      type: Function,
      default: () => ({}),
    },
    filename: {
      type: String,
      default: "exportPDF.pdf",
    },
  },
  data() {
    return {};
  },
  methods: {
    async exportPDF() {
      if (this.disabled) {
        return;
      }

      const containerRef = this.getContainerRef();

      const containerWidth = containerRef.offsetWidth;
      const containerHeight = containerRef.offsetHeight;

      const doc = new jsPDF({
        unit: "px",
        format: [containerWidth + 40, containerHeight + 100], // "a4",
        hotfixes: ["px_scaling"],
        scale: 1.1,
      });

      /*
      const availableWidth = doc.internal.pageSize.getWidth() - 40;
      const availableHeight = doc.internal.pageSize.getHeight() - 100;

      const ratio = Math.min(
         availableWidth / containerWidth,
         availableHeight / containerHeight
      ); */
      const canvas = await html2canvas(containerRef, {
        width: containerWidth,
        height: containerHeight,
      });
      const img = canvas.toDataURL("image/png", 0.8);

      doc.addImage(
        img,
        "PNG",
        20,
        20,
        containerWidth, // * ratio,
        containerHeight // * ratio
      );
      doc.save(this.filename);
    },
  },
};
</script>

<style scoped>
.menu-container {
  position: absolute;
  top: 15px;
  right: 15px;
}
</style>
