<template>
  <v-list-item link @click.native="$emit('exportCsv')" :disabled="disabled">
    <v-list-item-title>Export CSV</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "ExportCsvButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    csvData: {
      type: String,
      default: "",
    },
    filename: {
      type: String,
      default: "exportCSV.csv",
    },
  },
  data() {
    return {};
  },
  methods: {
    exportCSV() {
      if (this.disabled || this.csvData.length === 0) {
        return;
      }

      const downloadLink = document.createElement("a");
      const blob = new Blob(["\ufeff", this.csvData]);
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = this.filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
  },
  watch: {
    csvData() {
      this.exportCSV();
    },
  },
};
</script>
