<template>
  <v-card>
    <v-card-title class="align-start">
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Recherche (par : Site, Redac, Catégorie, Titre, URL, ...)"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <table-stats :data="stats"></table-stats>
        </v-col>
      </v-row>

      <dot-menu
        :isLoading="loading"
        @exportCsv="exportCSV"
        :csvButton="{
          csvData,
          filename: `perf-par-article-${this.startDate}_${this.endDate}.csv`,
        }"
      />
    </v-card-title>
    <v-card-text>
      <v-data-table
        :page="options.page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :sort-by="sortBy"
        :sort-desc="true"
        class="elevation-1"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
        :items-per-page="options.itemsPerPage"
      >
        <template v-slot:item.title="{ item }">
          <div class="truncateCell" :title="item.title">
            {{ item.title }}
          </div>
        </template>
        <template v-slot:item.adn_post_url_path_identifier="{ item }">
          <div class="truncateCell" :title="item.adn_post_url_path_identifier">
            <a :href="item.url" target="_blank">{{
              item.adn_post_url_path_identifier
            }}</a>
          </div>
        </template>
        <template v-slot:item.ingredients="{ item }">
          <div class="truncateCell" :title="item.ingredients">
            {{ item.ingredients }}
          </div>
        </template>
        <template v-slot:item.meta_description="{ item }">
          <div class="truncateCell" :title="item.meta_description">
            {{ item.meta_description }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.truncateCell {
  max-height: 80px;
  overflow: hidden;
}
</style>

<script>
import debounce from "lodash/debounce";

import DotMenu from "@/components/common/menus/DotMenu";
import TableStats from "@/components/regie/marmiton/cards/TableStatsCard";
import { extrapolateDatasetWithConsentRatio } from "@/components/regie/marmiton/utils/unapplyConsentRatio";

import useAxios from "@/hooks/useAxios";
import { runParallelAsyncs } from "@/utils/async";
import {
  decodeHtmlEntities,
  formatCurrency,
  formatNumber,
} from "@/utils/formatting";

export default {
  name: "PerfPerArticle",
  components: {
    DotMenu,
    TableStats,
  },
  props: {
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    ingredientsToInclude: {
      type: String,
      default: "",
    },
    ingredientsToExclude: {
      type: String,
      default: "",
    },
  },
  data() {
    const canSeeAllData = this.$can("manage", "regie_with_ca");

    return {
      forceCall: false,
      canSeeAllData,
      stats: {},
      search: "",
      numberOfPages: 0,
      total: 0,
      items: [],
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      csvData: "",
      headers: [
        {
          text: "Date création",
          align: "start",
          value: "created",
        },
        {
          text: "Rédac",
          align: "start",
          value: "redac_name",
        },
        {
          text: "Titre",
          value: "title",
          sortable: false,
        },
        {
          text: "URL",
          value: "url_path_identifier",
        },
        {
          text: "Ingrédients",
          value: "ingredients",
          sortable: false,
        },
        {
          text: "Méta descr",
          value: "meta_description",
          sortable: false,
        },
        ...(canSeeAllData
          ? [
              {
                text: "CA",
                value: "ca",
              },
            ]
          : []),
        {
          text: "Vues",
          value: "views",
        },
        ...(canSeeAllData
          ? [
              {
                text: "Sessions",
                value: "sessions",
              },
              {
                text: "Vues / Session",
                value: "views_per_session",
              },
            ]
          : []),
        {
          text: "Nb chars",
          value: "chars_count",
        },
        {
          text: "Catégorie",
          value: "category",
        },
        {
          text: "New",
          value: "is_new",
          sortable: false,
        },
        {
          text: "Diapo",
          value: "is_diapo",
          sortable: false,
        },
        {
          text: "Typologie",
          value: "ga_typology",
          sortable: false,
        },
        {
          text: "Type",
          value: "content_type",
          sortable: false,
        },
      ],
      sortBy: canSeeAllData ? "ca" : "views",
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    performances_url_params(forExport = false) {
      return {
        start_date: this.startDate,
        end_date: this.endDate,
        ingredients_to_include: this.ingredientsToInclude,
        ingredients_to_exclude: this.ingredientsToExclude,
        search_text: this.search,
        ...(forExport
          ? {}
          : {
              page: Math.max(this.options.page - 1, 0),
              limit: this.options.itemsPerPage,
            }),
        sort_by: this.options.sortBy?.[0] ?? "ca",
        sort_desc: this.options.sortDesc?.[0] ?? true,
      };
    },
    formatResult(data) {
      return data.items.map((item) => {
        item = extrapolateDatasetWithConsentRatio(item);

        return {
          ...item,
          ingredients: (item.ingredients ?? "").split(",").sort().join(", "),
          created: (item.created ?? "").split("T")[0],
          ...(this.canSeeAllData && item?.ca !== undefined
            ? { ca: formatCurrency(item.ca) }
            : {}),
          views: formatNumber(item.views),
          ...(this.canSeeAllData
            ? {
                sessions: formatNumber(item.sessions),
                views_per_session: formatNumber(item.views / item.sessions),
              }
            : {}),
          chars_count: formatNumber(item.chars_count),
          title: decodeHtmlEntities(item.title),
          meta_description: decodeHtmlEntities(item.meta_description),
        };
      });
    },
    async exportCSV() {
      if (
        !this.startDate ||
        this.startDate.length !== 10 ||
        !this.endDate ||
        this.endDate.length !== 10
      ) {
        alert("Sélectionnez une plage de date !");
        return false;
      }
      const { data } = await this.axiosGet(
        "/regie/marmiton/performances/export",
        this.performances_url_params(true)
      );

      this.csvData = data;
    },
    debouncedSearch: debounce(async function () {
      if (this.options.page !== 1) {
        this.options.page = 1;
        // updateData automatically call through watcher
      } else {
        this.getData();
      }
    }, 500),
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        (this.applyFilters || this.forceCall) &&
        !this.loading &&
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10
      ) {
        this.loading = true;
        const {
          performancesResponse: { data: performancesData },
          statsResponse: { data: statsData },
        } = await runParallelAsyncs({
          performancesResponse: this.axiosGet(
            "/regie/marmiton/performances",
            this.performances_url_params()
          ),
          statsResponse: this.axiosGet("/regie/marmiton/sessions-for-period", {
            start_date: this.startDate,
            end_date: this.endDate,
          }),
        });

        this.items = this.formatResult(performancesData);
        this.total = performancesData.total;
        this.numberOfPages = performancesData.total / this.options.itemsPerPage;

        performancesData.stats = extrapolateDatasetWithConsentRatio(
          performancesData.stats
        );
        const extrapolatedStatsData =
          extrapolateDatasetWithConsentRatio(statsData);
        this.stats = performancesData;

        this.$store.dispatch(
          "marmiton/updateMarmitonStatsForPeriod",
          extrapolatedStatsData
        );

        this.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }

      this.forceCall = false;
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
  },
  watch: {
    startDate() {
      this.debouncedGetData();
    },
    endDate() {
      this.debouncedGetData();
    },
    ingredientsToInclude() {
      this.debouncedGetData();
    },
    ingredientsToExclude() {
      this.debouncedGetData();
    },
    options() {
      this.forceCall = true;
      this.debouncedGetData();
    },
    search() {
      this.forceCall = true;
      this.$store.dispatch("marmiton/updateMarmitonSearchText", this.search);

      this.debouncedSearch();
    },
    applyFilters() {
      this.debouncedGetData();
    },
  },
};
</script>
