<template>
  <v-card :outlined="outlined">
    <v-card-title class="align-start">
      <v-row>
        <v-col cols="12">Top ingrédients</v-col>
      </v-row>
      <dot-menu
        :isLoading="loading"
        @exportCsv="exportCSV"
        :csvButton="{
          csvData,
          filename: `top-ingredients-${this.startDate}_${this.endDate}.csv`,
        }"
      />
    </v-card-title>
    <v-card-text class="align-start">
      <ingredient-filter
        class="mr-4"
        label="Ingrédients à exclure"
        storeGetter="getMarmitonTopIngredientsToExclude"
        storeUpdater="updateMarmitonTopIngredientsToExclude"
      ></ingredient-filter>
    </v-card-text>
    <v-card-text>
      <v-data-table
        :disable-filtering="true"
        :disable-sort="true"
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :class="classname"
        :page="options.page"
        :pageCount="numberOfPages"
        :server-items-length="total"
        :items-per-page="options.itemsPerPage"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style></style>

<script>
import axios from "@axios";
import debounce from "lodash/debounce";
import { formatCurrency, formatNumber } from "@/utils/formatting";
import DotMenu from "@/components/common/menus/DotMenu";
import IngredientFilter from "@/components/regie/common/filters/IngredientFilter";
import { ingredientListFromAutoCompleteValues } from "@/components/regie/common/utils/ingredientGroup.js";
import { extrapolateDatasetWithConsentRatio } from "@/components/regie/marmiton/utils/unapplyConsentRatio";

const defaultOptions = {
  page: 1,
  itemsPerPage: 10,
};

export default {
  name: "TopIngredients",
  components: {
    DotMenu,
    IngredientFilter,
  },
  props: {
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    department: {
      type: String,
      default: "regie",
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    classname: {
      type: String,
      default: "",
    },
  },
  data() {
    const canSeeAllData = this.$can("manage", "regie_with_ca");

    return {
      forceCall: false,
      canSeeAllData,
      items: [],
      loading: false,
      numberOfPages: 0,
      total: 0,
      options: { ...defaultOptions },
      csvData: "",
      headers: [
        {
          text: "Ingrédient",
          align: "start",
          value: "ingredient",
        },
        {
          text: "Nb recettes",
          value: "nb_occurrences",
        },
        {
          text: "Vues",
          value: "views",
        },
        ...(canSeeAllData
          ? [
              {
                text: "Sessions",
                value: "sessions",
              },
              {
                text: "CA",
                value: "ca",
              },
            ]
          : []),
      ],
      sortBy: canSeeAllData ? "ca" : "views",
    };
  },
  methods: {
    resetData() {
      this.items = [];
      this.numberOfPages = 0;
      this.total = 0;
      this.options = { ...defaultOptions };
    },
    formatResult(data) {
      return data.items.map((item) => {
        item = extrapolateDatasetWithConsentRatio(item);

        return {
          ...item,
          ...(this.canSeeAllData && item?.ca !== undefined
            ? { ca: formatCurrency(item.ca) }
            : {}),
          views: formatNumber(item.views),
          ...(this.canSeeAllData
            ? {
                sessions: formatNumber(item.sessions),
              }
            : {}),
        };
      });
    },
    topIngredientsUrlParams(forExport = false) {
      return {
        start_date: this.startDate,
        end_date: this.endDate,
        ingredients_to_exclude: this.ingredientsToExclude,
        ...(forExport
          ? {}
          : {
              page: Math.max(this.options.page, 1),
              limit: this.options.itemsPerPage,
            }),
      };
    },
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        (this.applyFilters || this.forceCall) &&
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10
      ) {
        this.loading = true;
        const { data } = await axios.get("/regie/marmiton/top-ingredients", {
          params: this.topIngredientsUrlParams(),
        });

        this.items = this.formatResult(data);
        this.total = data.total;
        this.numberOfPages = data.total / this.options.itemsPerPage;
        this.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }

      this.forceCall = false;
    },
    async exportCSV() {
      if (
        !this.startDate ||
        this.startDate.length !== 10 ||
        !this.endDate ||
        this.endDate.length !== 10
      ) {
        alert("Sélectionnez une plage de date !");
        return false;
      }

      const { data } = await axios.get(
        "/regie/marmiton/top-ingredients/export",
        {
          params: this.topIngredientsUrlParams(true),
        }
      );

      this.csvData = data;
    },
  },
  computed: {
    applyFilters() {
      return this.useMarmitonFilters
        ? this.$store.getters["common/getApplyFilters"]
        : true;
    },
    ingredientsToExclude() {
      const ingredients = this.useMarmitonFilters
        ? this.$store.getters["marmiton/getMarmitonTopIngredientsToExclude"]
        : [];

      return ingredientListFromAutoCompleteValues(ingredients).join(",");
    },
    useMarmitonFilters() {
      return ["marmiton"].includes(this.department);
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
    startDate() {
      this.debouncedGetData();
    },
    endDate() {
      this.debouncedGetData();
    },
    ingredientsToExclude() {
      this.forceCall = true;
      this.debouncedGetData();
    },
    options() {
      this.forceCall = true;
      this.debouncedGetData();
    },
    department() {
      this.resetData();
      this.debouncedGetData();
    },
  },
};
</script>
