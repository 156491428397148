<template>
  <div>
    <v-row class="d-flex ma-3 mb-0">
      <v-col cols="3">
        <period-filter></period-filter>
      </v-col>
      <v-col cols="3">
        <date-filter
          store="marmiton"
          storeGetter="getMarmitonDates"
          storeUpdater="updateMarmitonDates"
          periodType="past-only"
          :range="7"
        ></date-filter>
      </v-col>
      <v-col cols="6" class="d-flex">
        <v-spacer />
        <apply-filters :disabled="isLoading" outlined color="secondary" />
      </v-col>
    </v-row>
    <v-row class="d-flex ma-3 mt-0">
      <v-col cols="6">
        <ingredient-filter
          label="Ingrédients"
          storeGetter="getMarmitonIngredientsToInclude"
          storeUpdater="updateMarmitonIngredientsToInclude"
        ></ingredient-filter>
      </v-col>
      <v-col cols="6">
        <ingredient-filter
          label="Ingrédients à exclure"
          storeGetter="getMarmitonIngredientsToExclude"
          storeUpdater="updateMarmitonIngredientsToExclude"
        ></ingredient-filter>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import useAxios from "@/hooks/useAxios";
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DateFilter from "@/components/common/filters/DateFilter";
import IngredientFilter from "@/components/regie/common/filters/IngredientFilter";
import PeriodFilter from "@/components/regie/marmiton/filters/PeriodFilter";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    DateFilter,
    IngredientFilter,
    PeriodFilter,
  },
  data() {
    return {};
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
};
</script>

<style></style>
