<template>
  <div>
    <v-select
      outlined
      v-model="period"
      :items="periods"
      item-text="name"
      item-value="id"
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="Pour la période"
    ></v-select>
  </div>
</template>

<script>
import { getDateStr, ONE_DAY_MICROTIME } from "@/utils/dates";

const TODAY = new Date();
const YESTERDAY = new Date(TODAY.getTime() - 1 * ONE_DAY_MICROTIME);

export default {
  data() {
    return {
      periods: [
        {
          id: 30,
          name: "30 derniers jours",
        },
        {
          id: 91,
          name: "3 derniers mois",
        },
        {
          id: 182,
          name: "6 derniers mois",
        },
        {
          id: 365,
          name: "12 derniers mois",
        },
      ],
    };
  },
  computed: {
    period: {
      get() {
        return this.$store.getters["marmiton/getMarmitonPeriod"];
      },
      set(e) {
        this.$store.dispatch("marmiton/updateMarmitonPeriod", e);

        const endDate = YESTERDAY;
        const startDate = new Date(YESTERDAY.getTime() - e * ONE_DAY_MICROTIME);
        this.dates = [getDateStr(endDate), getDateStr(startDate)];
        this.$store.dispatch("marmiton/updateMarmitonDates", this.dates);
      },
    },
  },
};
</script>

<style></style>
