<template>
  <bar-chart
    :title="title"
    :colors="colors"
    :series="series"
    :labels="labels"
    :loading="loading"
    :datalabels="false"
    :yAxis="yAxis"
    :formatter="formatter"
  />
</template>

<script>
import debounce from "lodash/debounce";

import BarChart from "@/components/common/charts/BarChart";
import { ingredientListFromAutoCompleteValues } from "@/components/regie/common/utils/ingredientGroup.js";
import { extrapolateWithConsentRatio } from "@/components/regie/marmiton/utils/unapplyConsentRatio";

import useAxios from "@/hooks/useAxios";

import { colors } from "@/utils/constants";
import { formatNumber } from "@/utils/formatting";

export default {
  name: "ViewsByDay",
  props: {
    title: {
      type: String,
      require: true,
    },
  },
  components: {
    BarChart,
  },
  data() {
    return {
      forceCall: false,
      loading: false,
      colors: [colors.views],
      series: [
        {
          name: "Vues",
          data: [],
        },
      ],
      labels: [],
      formatter: (val) => formatNumber(val),
      yAxis: [
        {
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
      ],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  created() {
    this.forceCall = true;
    this.debouncedGetData();
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        (this.applyFilters || this.forceCall) &&
        !this.loading &&
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10
      ) {
        this.loading = true;
        const { data } = await this.axiosGet(
          "/regie/marmiton/sessions-by-days",
          {
            start_date: this.startDate,
            end_date: this.endDate,
            ingredients_to_include: this.ingredientsToInclude,
            ingredients_to_exclude: this.ingredientsToExclude,
            search_text: this.searchText,
          }
        );

        const series = [];
        const labels = [];
        for (const { date, views } of data) {
          // GA4 gives data for people that have consented.
          // Extrapolate to get data for every hypothetical visitor :
          series.push(extrapolateWithConsentRatio(views));
          labels.push(date);
        }

        this.series[0].data = series;
        this.labels = labels;

        this.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }

      this.forceCall = false;
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    startDate() {
      return this.$store.getters["marmiton/getMarmitonDates"][0];
    },
    endDate() {
      return this.$store.getters["marmiton/getMarmitonDates"][1];
    },
    ingredientsToInclude() {
      const ingredients =
        this.$store.getters["marmiton/getMarmitonIngredientsToInclude"];

      return ingredientListFromAutoCompleteValues(ingredients).join(",");
    },
    ingredientsToExclude() {
      const ingredients =
        this.$store.getters["marmiton/getMarmitonIngredientsToExclude"];

      return ingredientListFromAutoCompleteValues(ingredients).join(",");
    },
    searchText() {
      return this.$store.getters["marmiton/getMarmitonSearchText"];
    },
  },
  watch: {
    startDate() {
      this.debouncedGetData();
    },
    endDate() {
      this.debouncedGetData();
    },
    ingredientsToInclude() {
      this.debouncedGetData();
    },
    ingredientsToExclude() {
      this.debouncedGetData();
    },
    searchText() {
      this.debouncedGetData();
    },
    applyFilters() {
      this.debouncedGetData();
    },
  },
};
</script>
