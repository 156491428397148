<template>
  <div>
    <component v-if="logo" :is="logo"></component>
    <p class="text-2xl mb-6">Régie Marmiton</p>
    <v-card class="mb-6 pa-2">
      <filters></filters>
    </v-card>

    <views-by-day class="mb-6" title="Vues / Jours" />

    <top-ingredients-table
      class="mb-6"
      :startDate="startDate"
      :endDate="endDate"
      classname="elevation-1"
    ></top-ingredients-table>

    <perf-per-article-table
      class="mb-6"
      :startDate="startDate"
      :endDate="endDate"
      :ingredientsToInclude="ingredientsToInclude"
      :ingredientsToExclude="ingredientsToExclude"
    ></perf-per-article-table>
  </div>
</template>

<script>
import { ingredientListFromAutoCompleteValues } from "@/components/regie/common/utils/ingredientGroup.js";

import ViewsByDay from "@/components/regie/marmiton/cards/ViewsByDay.vue";
import Filters from "@/components/regie/marmiton/filters/Filters";
import PerfPerArticleTable from "@/components/regie/marmiton/tables/PerfPerArticle";
import TopIngredientsTable from "@/components/regie/marmiton/tables/TopIngredients";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "Marmiton",
  components: {
    Filters,
    PerfPerArticleTable,
    TopIngredientsTable,
    ViewsByDay,
  },
  mounted() {
    saveRmraView(this.$route);
  },
  props: {
    department: {
      type: String,
      required: true,
      validator(value) {
        return ["marmiton", "regie"].includes(value);
      },
    },
    logo: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    startDate() {
      return this.$store.getters["marmiton/getMarmitonDates"][0];
    },
    endDate() {
      return this.$store.getters["marmiton/getMarmitonDates"][1];
    },
    ingredientsToInclude() {
      const ingredients =
        this.$store.getters["marmiton/getMarmitonIngredientsToInclude"];

      return ingredientListFromAutoCompleteValues(ingredients).join(",");
    },
    ingredientsToExclude() {
      const ingredients =
        this.$store.getters["marmiton/getMarmitonIngredientsToExclude"];

      return ingredientListFromAutoCompleteValues(ingredients).join(",");
    },
  },
};
</script>

<style scoped></style>
